.worker-visa-page {
    padding: 70px 0px 0px;
}
.worker-visa-banner-heading {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}
.worker-visa-banner-heading-title {
    padding-left: 30px;
}
.worker-visa-banner-heading-title h5 {
    font-family: "Raleway", sans-serif !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    font-feature-settings: "calt" off;
    color: #333333;
    margin: 0;
}
.worker-visa-banner-heading-title h2 {
    font-weight: 400;
    font-size: 33px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
}
.worker-visa-banner p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #6e6f6f;
    margin: 0;
    padding-bottom: 25px;
}

.worker-grid {
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 100px;
}
.worker-cover {
    max-width: 514px;
    width: 100%;
    position: relative;
}
.worker-cover:after {
    content: "";
    background: linear-gradient(180deg, rgba(98, 67, 206, 0.2) 0%, rgba(98, 67, 206, 0) 100%);
    width: 368px;
    height: 368px;
    display: block;
    border-radius: 100%;
    position: absolute;
    bottom: -60px;
    right: -60px;
}
.worker-content {
    width: calc(100% - 514px);
}
.worker-content p {
    max-width: 570px;
}
.worker-cover img {
    max-width: 390px;
    width: 100%;
    position: relative;
    z-index: 9;
}
.visa-contact-content {
    background: #ffffff;
    box-shadow: 0px 24.5184px 32.0625px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 50px;
    position: relative;
    margin-bottom: -35px;
}
.visa-contact-content h2 {
    margin: 0;
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #333333;
}
.visa-contact-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #6e6f6f;
    opacity: 0.7;
    margin: 0;
    padding-bottom: 20px;
}
.visa-contact-form form {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.visa-contact-form form .form-control {
    background: #f5f8f8;
    border-radius: 122px;
    border-color: #f5f8f8;
    height: 54px;
    padding: 10px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    letter-spacing: -0.01em;
    color: #6e6f6f;
}
.visa-contact-form form .form-control::placeholder {
    color: #ced0d0;
}
.visa-contact-form form .btn {
    background: #f35c5c;
    border-radius: 33px;
    border-color: #f35c5c;
    height: 55px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #ffffff;
    box-shadow: none !important;
    transition: all 0.5s;
    position: relative;
}
.visa-contact-form form .btn:after {
    content: "";
    background: url(../../assets/images/header/btn-arrow-white.svg);
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 8px;
    position: relative;
    top: 2px;
    display: none;
}
.visa-contact-form form .btn:hover {
    background: #6243ce !important;
    border-color: #6243ce !important;
}
.visa-contact-form form .btn:hover:after {
    display: inline-block;
}
.visa-contact-form form .btn:focus {
    background: #ced0d0 !important;
    border-color: #ced0d0 !important;
}
.visa-contact-form form .btn:focus:hover:after {
    display: none;
}
.express-visa-banner {
    padding: 100px 0px 160px;
}
.express-visa-banner-heading {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}
.express-visa-banner-heading-title {
    padding-left: 30px;
}
.express-visa-banner-heading-title h5 {
    font-family: "Raleway", sans-serif !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    font-feature-settings: "calt" off;
    color: #333333;
    margin: 0;
}
.express-visa-banner-heading-title h2 {
    font-weight: 400;
    font-size: 33px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
}
.express-visa-banner p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #6e6f6f;
    margin: 0;
    padding-bottom: 25px;
}
.express-how-work {
    background: #f5f8f8;
    padding: 50px 0px;
    position: relative;
}
.express-how-work:before {
    content: "";
    background: url(../../assets/images/about/shape1.svg);
    position: absolute;
    top: -90px;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    z-index: 9;
}
.express-how-work-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
}
.express-how-work-content {
    width: calc(100% - 490px);
}
.express-how-work-cover {
    max-width: 390px;
    width: 100%;
    position: relative;
}
.express-how-work-content h3 {
    font-weight: 400;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    padding-bottom: 50px;
}
.express-how-work-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #6e6f6f;
    margin: 0;
    padding-bottom: 25px;
}
.express-how-work-cover img {
    max-width: 390px;
    width: 100%;
    position: relative;
    z-index: 9;
}
.express-how-work-cover:after {
    content: "";
    width: 378px;
    background: linear-gradient(180deg, rgba(98, 67, 206, 0.2) 0%, rgba(98, 67, 206, 0) 100%);
    height: 378px;
    display: block;
    border-radius: 100%;
    position: absolute;
    bottom: -111px;
    right: -150px;
}
.how-work-flip .express-how-work-content {
    order: 2;
}
.how-work-flip .express-how-work-cover {
    order: 1;
}
.how-work-flip .express-how-work-cover:after {
    right: auto;
    left: -140px;
    background: linear-gradient(180deg, rgba(243, 92, 92, 0.15) 0%, rgba(243, 92, 92, 0) 100%);
}
.express-program {
    padding: 70px 0px 100px;
}
.express-program-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.express-program-content {
    width: calc(100% - 490px);
}
.express-program-cover {
    max-width: 390px;
    width: 100%;
    position: relative;
}
.express-program-cover img {
    max-width: 390px;
    width: 100%;
    position: relative;
    z-index: 9;
}
.express-program-content h3 {
    font-weight: 400;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    padding-bottom: 50px;
}
.express-program-content h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    padding-bottom: 53px;
}
.express-program-content ul li {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #6e6f6f;
    padding-bottom: 20px;
    position: relative;
}
.express-program-content ul li:before {
    content: "";
    background: url(../../assets/images/visa/check.svg);
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    left: -35px;
    top: 2px;
}
.express-program-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #6e6f6f;
    margin: 0;
    padding-bottom: 25px;
}
.express-program-content ul {
    list-style-type: none;
}
.express-program-cover:after {
    content: "";
    width: 378px;
    background: linear-gradient(180deg, rgba(98, 67, 206, 0.2) 0%, rgba(98, 67, 206, 0) 100%);
    height: 378px;
    display: block;
    border-radius: 100%;
    position: absolute;
    bottom: -111px;
    right: -150px;
}
.student-program {
    background: #f5f8f8;
    padding: 70px 0px 75px;
    position: relative;
}
.student-program:before {
    content: "";
    background: url(../../assets/images/about/shape1.svg);
    position: absolute;
    top: -90px;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    z-index: 9;
}
.student-program + .visa-contact {
    background: #f5f8f8;
}

.holiday-visa .worker-visa-banner-heading {
    padding-bottom : 60px;
}
.holiday-visa .worker-visa-banner p {
    padding-bottom: 15px;
}
.holiday-visa .worker-grid {
    align-items: unset;
}
.holiday-visa .worker-content h3 {
    font-weight: 400;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    padding-bottom: 25px;
}
.holiday-visa .worker-content p {
    padding-bottom: 25px;
    max-width: 661px;
}
.holiday-visa ol {
    padding-left: 20px;
    margin: 0;
    padding-bottom: 25px;
}
.holiday-visa ol li {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    padding-bottom: 10px;
}
.holiday-visa .worker-grid {
    padding-bottom: 90px;
}
.holiday-visa .worker-cover {
    max-width: 390px;
}
.holiday-visa .worker-cover:after {
    display: none;
}
.how-works-section h2 {
    font-weight: 400;
    font-size: 33px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    padding-bottom: 50px;
}
.how-works-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.how-works-box {
    display: flex;
    gap: 20px;
}
.how-works-box p {
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #6E6F6F;
    padding-bottom: 0 !important;
    max-width: 1103px;
}
.how-works-box p span {
    font-weight: 700;
}
.how-works-box p a {
    color: #6E6F6F;
    text-decoration: underline !important;
}
.eligibilty-requirments {
    padding-top: 90px;
}
.eligibilty-requirments-title h2 {
    font-weight: 400;
    font-size: 33px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
}
.eligibilty-requirments-title p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #6E6F6F;
    padding: 10px 0px 30px 0px !important;
}
.eligibilty-requirments-list {
    max-width: 460px;
    width: 100%;
    margin: 0px auto;
}
.eligibilty-requirments-list p {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 20px !important;
}
.warning-note {
    max-width: 532px;
    width: 100%;
    margin: 0px auto;
}
.warning-note p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #6E6F6F;
    text-align: center;
}
.holiday-visa .curvebg.holiday-first {
    display: none;
}

@media screen and (max-width: 767px) {
    .express-visa-banner {
        padding: 40px 0px 100px;
    }
    .express-visa-banner-heading {
        justify-content: center;
        padding-bottom: 40px;
    }
    .express-visa-banner-heading-title h2 {
        font-size: 26px;
    }
    .express-how-work-content {
        width: 100%;
    }
    .express-how-work-cover {
        margin: 0px auto;
    }
    .express-how-work-content h3 {
        font-size: 20px;
        padding-bottom: 30px;
        text-align: center;
    }
    .express-how-work {
        padding: 20px 0px;
    }
    .how-work-flip .express-how-work-content {
        order: 1;
    }
    .how-work-flip .express-how-work-cover {
        order: 2;
    }
    .express-program-content {
        width: 100%;
    }
    .express-program {
        padding: 60px 0px 60px;
    }
    .express-program-content h3 {
        font-size: 20px;
        text-align: center;
        padding-bottom: 43px;
    }
    .express-program-content h4 {
        font-size: 18px;
        text-align: center;
        padding-bottom: 30px;
    }
    .student-program:before {
        top: -60px;
        height: 70px;
    }
    .express-program.student-program {
        padding-top: 20px;
    }
    .express-program-content ul li:before {
        top: 4px;
    }
    .express-how-work:before {
        top: -60px;
        height: 70px;
    }
    .worker-visa-page {
        padding: 40px 0px 0px;
    }
    .worker-visa-banner-heading {
        justify-content: center;
        padding-bottom: 40px;
    }
    .worker-visa-banner-heading-title h2 {
        font-size: 26px;
    }
    .worker-content {
        width: 100%;
    }
    .worker-content p {
        max-width: 100%;
    }
    .worker-cover {
        margin: 0px auto;
        padding-top: 15px;
    }
    .worker-grid {
        padding-top: 15px;
        padding-bottom: 87px;
    }
    .visa-contact-content {
        padding: 20px;
        box-shadow: 0px 24.5184px 32.0625px rgb(0 0 0 / 10%);
        z-index: 9;
    }
    .visa-contact-content h2 {
        font-size: 20px;
        padding-bottom: 15px;
    }
    .visa-contact-content p {
        padding-bottom: 15px;
    }
    .visa-contact-form form {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .visa-contact-form form .form-control {
        font-size: 14px;
    }
    .worker-visa-page.holiday-visa {
        padding-top: 60px;
    }
    .holiday-visa .worker-visa-banner-heading {
        padding-bottom: 20px;
    }
    .holiday-visa .worker-visa-banner p {
        max-width: 343px;
        width: 100%;
        margin: 0px auto;
        padding-bottom: 20px;
    }
    .holiday-visa .worker-grid {
        padding-top: 0;
        padding-bottom: 0px;
    }
    .holiday-visa .worker-content h3 {
        font-size: 20px;
        line-height: 120%;
        padding-bottom: 20px;
    }
    .holiday-visa ol {
        padding-bottom: 0px;
    }
    .holiday-visa ol li {
        padding-bottom: 22px;
    }
    .holiday-visa .worker-cover {
        padding-top: 0;
        max-width: 100%;
    }
    .holiday-visa .curvebg.holiday-first {
        display: block;
        margin-top: 46px;
    }
    .holiday-visa .curvebg.holiday-first img {
        height: 82px;
        object-fit: unset;
        width: 100%;
    }
    .how-works-section h2 {
        font-size: 26px;
    }
    .eligibilty-requirments {
        padding-top: 42px;
    }
    .eligibilty-requirments-title h2 {
        font-size: 26px;
    }
    .eligibilty-requirments-title p {
        padding: 30px 0px 40px 0px !important;
    }
    .eligibilty-requirments-list p {
        max-width: 100% !important;
        line-height: 24px;
        font-size: 16px;
    }
    .warning-note p {
        max-width: 345px !important;
        font-weight: 700;
        font-size: 16px;
        padding-top: 20px;
    }
    .how-works-box {
        gap: 10px;
    }
    .holiday-work-section {
        background: #F5F8F8;
        padding-bottom: 54px;
    }
}
