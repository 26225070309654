.manual-invoices-page {
  position: relative;
  padding: 60px 0px;
}
.manual-invoices-page h3 {
  font-size: 26px;
  line-height: 160%;
  padding-bottom: 30px;
  margin: 0;
  text-transform: capitalize;
  color: #222222;
}
.manual-invoices-content {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
  border-radius: 10px;
}
.invoice-form-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.invoice-form-grid .form-group {
  max-width: calc(25% - 20px);
  width: 100%;
}
.invoice-form-grid .form-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #222222;
  margin-bottom: 13px;
}
.invoice-form-grid .form-control {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 0px 2px rgba(238, 238, 238, 0.499809);
  border-radius: 6px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.387234px;
  color: #141414;
  width: 100%;
}
.invoice-form-grid .form-select {
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 0px 2px rgba(238, 238, 238, 0.499809);
  border-radius: 6px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.387234px;
  color: #141414;
}
.invoice-form-grid .form-control::placeholder {
  color: #141414;
}
.invoice-btn {
  max-width: calc(25% - 20px);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 88px;
}
.invoice-btn .btn,
.invoice-btn .btn:hover,
.invoice-btn .btn:focus {
  background: #f5a963;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 65px;
  max-width: 200px;
  width: 100%;
  border-color: #f5a963;
  height: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-right: 26px;
}
.invoice-pdf-menu .dropdown-toggle,
.invoice-pdf-menu .dropdown-toggle:hover,
.invoice-pdf-menu .dropdown-toggle:focus,
.invoice-pdf-menu .btn-primary:active,
.invoice-pdf-menu .show > .btn-primary.dropdown-toggle,
.invoice-pdf-menu .btn-primary:active:focus,
.invoice-pdf-menu .show > .btn-primary.dropdown-toggle:focus {
  color: #000;
  background: transparent;
  border: transparent;
  outline: none;
  box-shadow: none;
}
.invoice-pdf-menu .dropdown-toggle::after {
  display: none;
}
.invoice-form-grid .invoice-btn {
  min-height: unset;
}
.invoice-pdf-menu a.dropdown-item {
  overflow: auto;
  scroll-behavior: smooth;
}
.invoice-pdf-menu a.dropdown-item::-webkit-scrollbar {
  height: 4px;
}
.invoice-pdf-menu a.dropdown-item::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.invoice-pdf-menu a.dropdown-item::-webkit-scrollbar-thumb {
  background: #888;
}
.refund-btn {
  max-width: unset;
  width: 100%;
  display: unset;
  min-height: unset;
}

.refund-btn .btn,
.refund-btn .btn:hover,
.refund-btn .btn:focus {
  height: 40px;
  max-width: 100px;
}
.refund-popup .login-form {
    max-width: unset;
}
@media (max-width: 768px) {
  .invoice-form-grid .form-group,
  .invoice-btn {
    max-width: 100%;
  }
  .invoice-btn {
    justify-content: center;
    align-items: center;
    min-height: fit-content;
  }
}
