.users-list {
  position: relative;
  padding: 60px 0px;
}
.users-list h3 {
  font-size: 26px;
  line-height: 160%;
  margin: 0;
  text-transform: capitalize;
  color: #222222;
}
.user-list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.users-page-content table tr th,
.users-page-content table tr td {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.387234px;
  vertical-align: middle;
  color: #222222;
  border: none;
  padding: 18px 20px 15px;
}
.users-page-content table tr td {
  border-bottom: 1px solid #f5f5f5;
}
.users-page-content table tr:last-child td {
  border: none;
}
.users-page-content table tr th {
  font-weight: 700;
  border: none;
}
.users-page-content .table > :not(:first-child) {
  border-top: 2px solid #f5f5f5;
}
.users-page-content {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 10px 0px;
}
.users-page-content table.table {
  margin: 0;
}
.user_btn_dot .dropdown-toggle,
.user_btn_dot .dropdown-toggle:hover,
.user_btn_dot .dropdown-toggle:focus,
.user_btn_dot .show > .btn-primary.dropdown-toggle {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}
.user_btn_dot .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(35, 40, 59, 0.1);
  border-radius: 6px;
  border: none;
  padding: 0;
}
.user_btn_dot .dropdown-menu a {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #23283c;
  padding: 14px 20px;
  border-bottom: 1px solid #eff0f7;
}
.user_btn_dot .dropdown-menu a:last-child {
  border: none;
}
.user_btn_dot .dropdown-menu a:hover {
  background: transparent;
  color: #23283c;
}
.consultation-popup.user-update-popup .modal-lg {
  max-width: 440px;
}
.user-update-popup .consultation-popup-content {
  padding: 40px;
}
.user-popup .modal-lg {
  max-width: 440px;
}
.user-popup .modal-content {
  background: #ffffff;
  box-shadow: 0px 24.5184px 32.0625px rgb(0 0 0 / 10%);
  border-radius: 20px;
  border: none;
}
.user-popup .modal-header {
  padding: 0;
  border: none;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 9;
}
.user-popup .modal-body {
  padding: 0;
}
.user-popup .modal-header .btn-close {
  background: #6243ce;
  width: 45px;
  height: 45px;
  opacity: 1;
  border-radius: 100%;
  padding: 0;
}
.user-popup .btn-close:before {
  content: '';
  background: url(../../assets/images/header/close-white.svg);
  width: 18px;
  height: 18px;
  background-size: cover;
  display: block;
  background-repeat: no-repeat;
  margin: 0px auto;
}
.user-block {
  padding: 50px 50px 50px 50px;
}
.user-block h3 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 30px;
  text-align: center;
}
.user-block-btn {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.user-block-btn .btn {
  width: 49%;
  height: 50px;
  background: #f35c5c;
  border-radius: 30px;
  border-color: #f35c5c;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: none;
}
.user-block-btn .btn:hover,
.user-block-btn .btn:focus,
.user-block-btn .btn-primary:active:focus {
  box-shadow: none !important;
  background: #6243ce;
  border-color: #6243ce;
}
.user-block-btn .btn:first-child {
  background: transparent;
  color: #f35c5c;
}
.user-block-btn .btn:first-child:hover,
.user-block-btn .btn:first-child:focus,
.user-block-btn .btn:first-child:active:focus {
  color: #6243ce;
}
.add-user-popup-content {
  max-width: unset;
  padding: 40px;
}
.users-page-content-input .form-control {
  max-width: 500px;
  width: 100%;
  background: #f5f8f8;
  border-radius: 122px;
  height: 54px;
  border-color: #f5f8f8;
  margin-bottom: 20px;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  width: 100%;
  color: #6e6f6f;
  box-shadow: none !important;
}
.users-page-content .table-responsive{
    min-height: 300px;
}