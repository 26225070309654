@import "https://fonts.googleapis.com/css2?family=The+Nautigal:wght@400;700&display=swap";
.landing-page {
  position: relative;
}
.bubble {
  position: relative;
  width: 100%;
  user-select: none;
}
.bubble1 {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 0;
  right: 0;
  background: #f6a081;
  opacity: 0.4;
  border-radius: 5.26966px;
  transform: rotate(-15.25deg);
}
.bubble2 {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -30px;
  left: 0;
  background: #d38ce7;
  opacity: 0.4;
  border-radius: 109.492px;
}
.bubble3 {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 69px;
  left: -19px;
  background: #f49f88;
  opacity: 0.4;
  border-radius: 109.492px;
}
.bubble4 {
  position: absolute;
  width: 90px;
  height: 90px;
  top: -344px;
  left: 0;
  background: #e798ae;
  opacity: 0.4;
  border-radius: 146.379px;
  transform: rotate(-15deg);
}
.bubble5 {
  position: absolute;
  width: 140px;
  height: 140px;
  top: -600px;
  right: 0;
  background: #f6a081;
  opacity: 0.4;
  border-radius: 146.379px;
  transform: rotate(-15deg);
}
.bubble6 {
  position: absolute;
  width: 36px;
  height: 36px;
  bottom: 77px;
  left: 120px;
  background: #ca87ff;
  opacity: 0.4;
  border-radius: 5.26966px;
  transform: rotate(-15deg);
}
.bubble7 {
  position: absolute;
  width: 96px;
  height: 96px;
  top: -319px;
  left: 0;
  background: #f6a081;
  opacity: 0.3;
  transform: rotate(-105deg);
  border-radius: 100%;
}
.bubble8 {
  position: absolute;
  width: 48px;
  height: 48px;
  top: -277px;
  right: 120px;
  background: #f3b634;
  opacity: 0.3;
  transform: rotate(-105deg);
  border-radius: 100%;
}
.bubble9 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -380px;
  right: 150px;
  background: #f6a081;
  opacity: 0.3;
  transform: rotate(-105deg);
  border-radius: 100%;
}
.bubble10 {
  position: absolute;
  width: 64px;
  height: 64px;
  top: -480px;
  right: 0;
  background: #7bb1a2;
  opacity: 0.2;
  transform: rotate(-105deg);
  border-radius: 100%;
}
.bubble11 {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -500px;
  left: -120px;
  background: #e798ae;
  opacity: 0.4;
  border-radius: 146.379px;
  transform: rotate(-15deg);
}
.bubble12 {
  position: absolute;
  width: 48px;
  height: 48px;
  bottom: -160px;
  right: 50px;
  background: #c6abff;
  opacity: 0.3;
  transform: rotate(-13.3deg);
  border-radius: 100%;
}
.landing-header {
  position: absolute;
  width: 100%;
}
.landing-logo {
  text-align: center;
  padding: 30px 0px;
}
.landing-logo img {
  max-width: 110px;
}
.landing-top-banner {
  width: 100%;
  height: 474px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}
.landing-top-banner-content h2 {
  font-family: "The Nautigal", cursive !important;
  font-weight: 700;
  font-size: 143.31px;
  line-height: 104%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 3.92959px 3.92959px rgb(0 0 0 / 25%);
  transform: rotate(-0.05deg);
  margin: 0;
}
.landing-top-banner-content h4 {
  font-weight: 900;
  font-size: 54.8914px;
  line-height: 104%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  transform: rotate(-0.05deg);
  margin: 0;
}
.landing-tell-more {
  position: relative;
  padding: 45px 0px 112px;
}
.landing-tell-more-content {
  text-align: center;
}
.landing-tell-more-content h2 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 20px;
}
.landing-tell-more-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
  max-width: 1063px;
  margin: 0px auto;
  padding-bottom: 20px;
}
.landing-working-holiday {
  position: relative;
  padding-bottom: 100px;
}
.landing-working-holiday-header h2 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 35px;
}
.landing-working-holiday-header p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
  max-width: 1160px;
  margin: 0px auto;
  padding-bottom: 42px;
}
.landing-ticket-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  filter: drop-shadow(0px 24.5184px 32.0625px rgba(0, 0, 0, 0.1));
}
.landing-ticket-left {
  width: 469px;
  height: 528px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-position: center;
  position: relative;
}
.landing-ticket-right {
  background: url(../../assets/images/landing/right-card.svg);
  width: 702px;
  height: 531px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-position: center;
  margin-left: -5px;
  position: relative;
}
.landing-ticket-left-header h4 {
  font-weight: 400;
  font-size: 28.6446px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: 0;
}
.landing-ticket-left-header {
  padding: 24px 10px;
}
.landing-ticket-left-header p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin: 0;
}
.landing-ticket-left-header p span {
  font-weight: 400;
  font-size: 17.0842px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  font-family: "DM Serif Display", serif !important;
  margin-right: 5px;
}
.landing-ticket-left-barcode {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-30%);
}
.landing-ticket-right-header {
  display: flex;
  padding: 30px 10px 25px;
  max-width: 620px;
  width: 100%;
  justify-content: space-between;
  margin: 0px auto;
}
.landing-ticket-right-header-box {
  display: flex;
  align-items: flex-start;
}
.landing-ticket-right-header-info span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: "calt" off;
  color: #333333;
  display: block;
  padding-bottom: 5px;
  text-align: left;
}
.landing-ticket-right-header-info {
  padding-left: 18px;
}
.landing-ticket-right-header-info h5 {
  margin: 0;
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #6243ce;
}
.landing-ticket-right-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 55px;
  padding-top: 25px;
  max-width: 484px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.landing-ticket-right-stamp {
  position: absolute;
  right: 62px;
  bottom: 36px;
}
.landing-ticket-right-content ul li {
  position: relative;
  margin-bottom: 10px;
}
.landing-ticket-right-content ul li span {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  display: inline-block;
  color: #333333;
  text-align: left;
  background: rgba(243, 92, 92, 0.1);
  border-radius: 3px;
  padding: 3px 10px;
  padding-left: 38px;
}
.landing-ticket-right-content ul li span:before {
  content: "";
  background: url(../../assets/images/landing/check.svg);
  width: 13px;
  height: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 10px;
  top: 7px;
}
.ticket-scroll-stamp {
  position: relative;
}
.ticket-scroll-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.landing-form {
  position: relative;
  padding-bottom: 107px;
  background: url(../../assets/images/landing/bg-gray-curve.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}
.landing-form-content {
  position: relative;
}
.landing-form-content h2 {
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 25px;
}
.landing-form-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
  margin: 0px auto;
  max-width: 584px;
  padding-bottom: 33px;
}
.landing-form-content form {
  max-width: 520px;
  margin: 0px auto;
}
.landing-form-content form .form-control {
  background: #ffffff;
  border: 1px solid #ced0d0;
  border-radius: 122px;
  margin-bottom: 10px;
  padding: 10px 24px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  width: 100%;
}
.landing-form-content form .form-control::placeholder {
  color: #6e6f6f;
}
.landing-form-content form .orange-button {
  max-width: 100% !important;
  margin: 0;
}
.landing-form-content .form-check {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #6e6f6f;
  justify-content: center;
  padding-top: 12px;
  padding-left: 0;
}
.landing-form-content .form-check input {
  margin: 0;
  margin-right: 12px;
  width: 15px;
  height: 15px;
  border: 2px solid #6243ce;
  background-color: transparent;
}
.landing-form-content .form-check-input:focus {
  box-shadow: none;
}
.landing-form-content .form-check-input:checked[type="checkbox"] {
  background-image: url(../../assets/images/landing/checkboxTick.svg);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.landing-testimonial {
  position: relative;
  padding: 100px 0px 150px;
}
.landing-testimonial:before {
  content: "";
  background: url(../../assets/images/landing/testimonial-curve.svg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.landing-testimonial-content {
  position: relative;
}
.landing-testimonial-content h2 {
  margin: 0;
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  padding-bottom: 206px;
}
.landing-testimonial-slider {
  max-width: 700px;
  background: #ffffff;
  box-shadow: 0px 12.52px 22.06px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px;
  margin: 0px auto;
}
.landing-star {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}
.landing-star img {
  margin: 0px 3px;
}
.landing-testimonial-box p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
  margin: 0;
  padding-bottom: 25px;
}
.landing-testimonial-box h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #6243ce;
  margin: 0;
  padding-bottom: 5px;
}
.landing-testimonial-box span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  display: flex;
  justify-content: center;
}
.landing-testimonial-sponser {
  padding-top: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landing-testimonial-sponser-box img {
  opacity: 0.3;
}
.landing-testimonial-slider .slick-dots li img {
  width: 62px;
  height: 62px;
  border-radius: 100%;
}
.landing-testimonial-slider .slick-dots {
  bottom: inherit;
  top: -150px;
}
.landing-testimonial-slider .slick-dots li {
  width: 62px;
  height: 62px;
  margin: 0px 36px;
}
.landing-testimonial-slider .slick-dots li.slick-active img {
  width: 62px;
  height: 62px;
  border: 3px solid #f35c5c;
}
.landing-testimonial-slider .slick-dots li.slick-active {
  width: 62px;
  height: 62px;
}
.landing-about {
  position: relative;
  padding: 93px 0px 47px;
}
.landing-about h2 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  max-width: 837px;
  margin: 0px auto;
  padding-bottom: 35px;
}
.landing-about-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.landing-about-content {
  width: 100%;
  text-align: center;
}
.landing-about-content h4 {
  margin: 0;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  font-weight: 400;
  padding: 15px 0px 10px;
}
.landing-about-icons {
  max-width: 475px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.landing-about-icons-box {
  width: calc(50% - 20px);
  text-align: center;
}
.landing-about-icons-box img {
  width: 70px;
  padding-bottom: 20px;
}
.landing-about-icons-box h5 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
}
.landing-about-content p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
  font-weight: 500;
  margin: 0;
  padding-bottom: 15px;
  padding-top: 20px;
}
.landing-about-content .orange-button {
  margin: 0;
  margin-top: 20px;
}
.landing-bottom-banner {
  width: 100%;
  height: 497px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.landing-footer {
  background: #333333;
  padding: 70px 0px 55px;
}
.landing-footer-logo {
  text-align: center;
  padding: 0px 0px 20px;
}
.landing-footer-logo img {
  max-width: 110px;
}
.landing-footer p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  max-width: 1100px;
  margin: 0px auto;
  padding-bottom: 20px;
}
.landing-footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.landing-footer ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  margin: 0px 15px;
}
.payment {
  text-align: center;
}
.landing-thank-you {
  padding: 0px;
  max-width: 524px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced0d0;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  margin: 100px auto;
}
.thankyou-cta {
  text-align: center;
  padding-top: 50px;
}
.thankyou-cta p {
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  max-width: 420px;
  margin: 0px auto;
  color: #333333;
}
.thankyou-cta span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  max-width: 420px;
  margin: 0px auto;
  color: #6E6F6F;
  display: block;
  padding-bottom: 15px;
}
.landing-thank-you .thank-you-content p {
  max-width: 100%;
}
.consultation-popup.landing-popup .modal-lg {
  max-width: 524px;
}
.landing-page-v2 .landing-top-banner {
  height: 540px;
}
.landing-page-v2 .landing-working-holiday {
  margin-top: -360px;
}
.landing-page-v2 .landing-working-holiday-header h2 {
  color: #fff;
  padding-bottom: 10px;
}
.landing-page-v2 .landing-working-holiday-header p {
  color: #fff;
  padding-bottom: 20px;
}
.landing-page-v2 .landing-working-holiday-header h2 span {
  font-size: 72px;
  display: block;
  line-height: 72px;
}
.landing-page-v3 .landing-logo {
  text-align: left;
}
.landing-top-banner-v3 {
  background: url(../../assets/images/landing/top-banner-v3.png);
  width: 100%;
  height: 827px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
.landing-top-banner-v3-content h2 {
  margin: 0;
  max-width: 450px;
  font-style: italic;
  font-weight: 400;
  font-size: 106px;
  line-height: 93%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 2px 1px rgb(0 0 0 / 20%);
}
.landing-top-banner-v3-icon {
  position: absolute;
  top: 50%;
  left: 325px;
  transform: translateY(-50%);
}
.term-link {
  display: flex;
  position: relative;
  padding-left: 18px;
  margin-top: 10px;
  font-size: 13px;
}
.term-link div {
  position: absolute;
  left: 0;
  top: 0;
}
.term-link a {
  display: inline-block;
}
.landing-page-v3 .landing-form {
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
  background: url(../../assets/images/landing/form_v3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.landing-page-v3 .landing-form-content {
  background: #ffffff;
  border-radius: 10px;
  max-width: 640px;
  margin: 0px auto;
  padding: 30px;
}
.landing-page-v4 .landing-logo {
  text-align: center;
}
.landing-page-v4 .landing-top-banner-v3 {
  background: url(../../assets/images/landing/top-banner-v4.png);
  width: 100%;
  height: 826px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
.landing-page-v4 .landing-top-banner-v3-content h2 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 1px 12px rgba(0, 0, 0, 0.17);
}
.landing-top-banner-v3-icon {
  position: absolute;
  top: 50%;
  left: 325px;
  transform: translateY(-50%);
}
.formSelectContents {
  position: relative;
}
.formSelectContents > img {
  position: absolute;
  top: 12px;
  left: 21px;
  z-index: 1 !important;
}
.formSelectContents img {
  /* position: absolute; */
  /* top: 15px;
    left: 23px; */
  width: 23px;
  height: 15px;
  margin-right: 10px;
}
.formSelectContents .countryOption {
  position: relative;
}
.formSelectContents .countryOption img {
  margin-right: 32px;
  z-index: 9 !important;
  position: relative;
}
.formSelectContents .countryOption span {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #9b9b9b;
}
.formFieldGrid
  .formSelectContents
  :global(.react-select__indicator.react-select__dropdown-indicator)
  svg {
  position: absolute;
  left: 52px;
  top: 12px;
}
.formFieldGrid .formSelectContents :global(.react-select__placeholder) {
  padding-left: 54px;
}
.formSelectContents :global(.react-select__value-container) {
  padding-left: 45px;
}
/* .formSelectContents :global(.react-select__placeholder) {
  padding-left: 35px;
} */
.formSelectContents :global(.react-select__indicator) {
  padding-right: 16px;
}
/* .react-select__value-container {
    padding-left: 45px;
} */

.formSelectContents {
  width: 100%;
}

.flagInputNew > img {
  top: 20px;
}
.flagInputNew .react-select__placeholder {
  padding-left: 45px;
}
.flagInputNew .error-msg {
  padding: 0;
}

@media screen and (max-width: 1280px) {
  .landing-ticket-grid {
    transform: scale(0.8);
    margin-top: -50px;
  }
}
@media screen and (max-width: 1224px) {
  .landing-ticket-left {
    width: 380px;
    height: 426px;
  }
  .landing-ticket-right {
    width: 568px;
    height: 430px;
  }
  .landing-ticket-right-header {
    padding: 20px 10px 10px;
    max-width: 475px;
  }
  .landing-ticket-left-header {
    padding: 15px 10px;
  }
  .landing-ticket-left-header h4 {
    font-size: 23px;
  }
  .landing-ticket-right-content ul {
    padding-top: 20px;
  }
  .landing-ticket-right-content ul li span {
    font-size: 14px;
    line-height: 20px;
  }
  .landing-ticket-right-content ul li {
    margin-bottom: 6px;
  }
  .landing-ticket-right-stamp img {
    width: 80px;
  }
  .landing-ticket-right-stamp {
    bottom: 18px;
  }
  .ticket-scroll-stamp svg {
    width: 100px;
  }
}
@media screen and (max-width: 991px) {
  .landing-top-banner-content h2 {
    font-size: 100px;
    line-height: 90px;
  }
  .landing-top-banner-content h4 {
    font-size: 40px;
    line-height: 40px;
  }
  .landing-about-content {
    width: 100%;
  }
  .landing-about-icons {
    margin: 0px auto;
    padding-top: 30px;
  }
  .landing-testimonial-sponser-box img {
    width: 80%;
  }
  .landing-working-holiday {
    padding-bottom: 30px;
  }
  .landing-ticket-left {
    background: url(../../assets/images/landing/left-card-mbl.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 343px;
    height: 275px;
    margin: 0px auto;
  }
  .landing-ticket-right {
    background: url(../../assets/images/landing/right-card-mbl.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 345px;
    height: 636px;
    margin: 0px auto;
  }
  .landing-ticket-grid {
    flex-direction: column;
    transform: scale(1);
    margin-top: -10px;
  }
  .landing-ticket-left-header h4 {
    font-size: 20px;
    line-height: 120%;
  }
  .landing-ticket-left-header p {
    font-size: 16px;
    line-height: 24px;
  }
  .landing-ticket-left-barcode {
    position: absolute;
    left: 50%;
    top: auto;
    transform: translateX(-50%) rotate(90deg);
    width: 40px;
    overflow: hidden;
    margin: 0px auto;
    margin-top: 20px;
  }
  .landing-ticket-left-header {
    padding: 25px 10px;
  }
  .landing-ticket-right-content ul {
    margin: 20px auto;
    padding: 20px;
  }
  .landing-ticket-right-header-box {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .landing-ticket-right-header-info {
    padding: 0;
  }
  .landing-ticket-right-header-info span {
    text-align: center;
    font-size: 12px;
    line-height: 100%;
    padding-top: 10px;
  }
  .landing-ticket-right-header-info h5 {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
  .landing-ticket-right-header {
    padding: 22px 30px 10px;
  }
  .landing-ticket-right-stamp {
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(0.7);
  }
  .landing-ticket-right-content ul li {
    margin-bottom: 10px;
  }
  .landing-ticket-right-content ul li span {
    display: block;
  }
  .bubble2 {
    width: 41.05px;
    height: 41.05px;
    top: -370px;
    left: 25px;
  }
  .bubble3 {
    width: 18px;
    height: 18px;
    top: -325px;
    left: 0px;
  }
  .bubble5 {
    width: 40px;
    height: 40px;
    top: -591px;
  }
  .bubble6 {
    display: none;
  }
  .bubble4 {
    display: none;
  }
  .bubble7 {
    display: none;
  }
  .bubble9 {
    display: none;
  }
  .bubble8 {
    width: 23.4px;
    height: 23.4px;
    top: -570px;
    right: 0;
  }
  .bubble10 {
    display: none;
  }
  .bubble11 {
    display: none;
  }
  .bubble12 {
    width: 47.31px;
    height: 47.31px;
    bottom: -95px;
    right: 3px;
  }
}
@media screen and (max-width: 767px) {
  .landing-testimonial-slider .slick-dots li {
    margin: 0px 5px;
  }
  .landing-testimonial-slider .slick-dots li.slick-active {
    width: 62px;
    height: 62px;
  }
  .landing-testimonial-slider .slick-dots li.slick-active img {
    width: 62px;
    height: 62px;
  }
  .landing-page-v2 .landing-working-holiday-header h2 {
    font-size: 26px;
    line-height: 100%;
    padding-bottom: 20px;
  }
  .landing-page-v2 .landing-working-holiday-header h2 span {
    font-size: 38px;
    line-height: 100%;
  }
}
@media screen and (max-width: 480px) {
  .landing-top-banner {
    background: url(../../assets/images/landing/top-banner-mbl.png);
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .landing-bottom-banner {
    background: url(../../assets/images/landing/bottom-banner-mbl.png);
    width: 100%;
    height: 235px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .landing-top-banner-content h2 {
    font-size: 57.7094px;
    line-height: 104%;
  }
  .landing-top-banner-content h4 {
    font-size: 28.4705px;
    line-height: 104%;
  }
  .landing-tell-more {
    position: relative;
    padding: 45px 0px 50px;
  }
  .landing-tell-more-content h2 {
    font-size: 23px;
    line-height: 120%;
  }
  .landing-working-holiday-header h2 {
    font-size: 23px;
    line-height: 120%;
    padding-bottom: 20px;
  }
  .landing-form {
    padding-bottom: 60px;
  }
  .landing-form-content h2 {
    font-size: 23px;
    line-height: 120%;
    padding-bottom: 20px;
  }
  .landing-form-content .form-check {
    justify-content: flex-start;
  }
  .landing-testimonial {
    padding: 52px 0px 50px;
  }
  .landing-testimonial-content h2 {
    font-size: 23px;
    padding-bottom: 142px;
  }
  .landing-testimonial-slider .slick-dots {
    top: -130px;
  }
  .landing-testimonial-box p {
    font-size: 16px;
    line-height: 24px;
  }
  .landing-testimonial-box h4 {
    font-size: 18px;
    line-height: 120%;
  }
  .landing-testimonial-sponser {
    padding-top: 33px;
  }
  .landing-about {
    padding: 50px 0px 50px;
  }
  .landing-about h2 {
    font-size: 23px;
    line-height: 120%;
    padding-bottom: 5px;
  }
  .landing-about-content p {
    text-align: center;
  }
  .landing-about-content .orange-button {
    margin: 0px auto;
    margin-top: 20px;
    display: block;
  }
  .landing-about-icons-box h5 {
    font-size: 16px;
    line-height: 120%;
  }
  .landing-footer {
    padding: 30px 0px 55px;
  }
  .landing-footer ul {
    flex-direction: column;
    gap: 10px;
  }
  .bubble2 {
    width: 41.05px;
    height: 41.05px;
    top: -353px;
    left: 25px;
  }
  .bubble3 {
    width: 18px;
    height: 18px;
    top: -313px;
    left: 0px;
  }
  .bubble5 {
    width: 40px;
    height: 40px;
    top: -591px;
  }
  .bubble6 {
    display: none;
  }
  .bubble4 {
    display: none;
  }
  .bubble7 {
    display: none;
  }
  .bubble9 {
    display: none;
  }
  .bubble8 {
    width: 23.4px;
    height: 23.4px;
    top: -570px;
    right: 0;
  }
  .bubble10 {
    display: none;
  }
  .bubble11 {
    display: none;
  }
  .bubble12 {
    width: 47.31px;
    height: 47.31px;
    bottom: -95px;
    right: 3px;
  }
  .landing-about-content h4 {
    max-width: 300px;
    margin: 0px auto;
  }
  .landing-testimonial:before {
    background-position: right;
  }
  .landing-gallery .slick-slide img {
    height: 200px;
    object-fit: cover;
  }
  .landing-gallery .slick-list {
    padding: 0 !important;
  }
}
@media screen and (max-width: 375px) {
  .landing-bottom-banner {
    height: 197px;
  }
}
@media screen and (max-width: 360px) {
  .landing-testimonial-slider .slick-dots li {
    margin: 0px 3px;
    width: 50px;
    height: 50px;
  }
  .landing-testimonial-slider .slick-dots li.slick-active {
    width: 50px;
    height: 50px;
  }
  .landing-testimonial-slider .slick-dots li.slick-active img {
    width: 50px;
    height: 50px;
  }
  .landing-testimonial-slider .slick-dots li img {
    width: 50px;
    height: 50px;
  }
}
