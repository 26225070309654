.WorldTravel-page {
  padding: 80px 0px 0px 0px;
  position: relative;
}

.WorldTravel-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  padding-bottom: 41px;
}

.WorldTravel-intro h2 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0px;
}

.WorldTravel-intro p {
  max-width: 1037px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
  margin: 0px;
}

.WorldTravel-transform {
  /* margin-top: 53px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  position: relative;
}

.worldTravelTransform-content {
  max-width: 570px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
}

.worldTravelTransform-content h2 {
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
  margin-bottom: 0px;
}

.worldTravelTransform-content span {
  color: #6243ce;
}

.worldTravelTransform-content p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
}
.worldTravelTransform-image {
  max-width: calc(100% - 570px);
  width: 100%;
  text-align: center;
}

.worldTravelTransform-image img {
  max-width: 400px;
  width: 100%;
}

.WorldTravel-descp-content {
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.WorldTravel-plans {
  position: relative;
  padding-top: 36px;
  width: 100%;
  background: #f5f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.WorldTravels-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 86px;
}

.WorldTravel-plans::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 86px;
  background-image: url(../../assets/images/worldTravel/curveBg.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #fff;
  top: 0;
  left: 0;
}

.WorldTravel-plansTitle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.WorldTravel-plansTitle h2 {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}

.WorldTravel-plansTitle p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
}

.WorldTravel-plansCards {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.WorldTravel-plansCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* padding: 10px 10px 20px 10px; */
  gap: 20px;
  max-width: 370px;
  height: 100%;
  /* background: #ffffff; */
  /* box-shadow: 0px 24.5184px 32.0625px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
}

.WorldTravel-plansCard .plansCardBanner {
  width: 100%;
  border-radius: 30px;
}

.inculsivePoster {
  position: absolute;
  transform: rotate(11.53deg);
  width: 67.29px;
  height: 67.29px;
  background: #6243ce;
  border: 2.17578px solid #ffffff;
  box-shadow: 0px 5.45396px 7.2526px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  right: -30px;
  top: 20px;
  display: grid;
  place-content: center;
}

.inculsivePoster h4 {
  font-family: "Raleway", cursive !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16.3451px;
  line-height: 11px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: "calt" off;
  color: #ffffff;
  margin-bottom: 0px;
}

.inculsivePoster p {
  color: #fff;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
}

.plansCard-packagelist li {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: flex-start;
}

.plansCard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.plansCard-content h2 {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
}

.plansCard-content p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
}

.plansCard-content h6 {
  font-family: "Raleway" !important;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
}

.plansCard-content h6 span {
  font-weight: 700;
}
.plansCard-contentPrice {
  display: flex;
  align-items: center;
  gap: 20px;
}

.plansCard-contentPrice h4 {
  margin-bottom: 0px;
}

.plansCard-contentPrice button,
.plansCard-contentPrice button:focus,
.plansCard-contentPrice button:active {
  padding: 20px;
  gap: 10px;
  width: 58.5px;
  height: 33px;
  overflow: hidden;
  background: #f35c5c !important;
  background-color: #f35c5c !important;
  border-color: #f35c5c !important;
  border: 1px solid #f35c5c !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.plansCard-contentPrice button:hover {
  padding: 20px;
  gap: 10px;
  width: 117.5px;
  height: 33px;
  background: #f35c5c;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.plansCard-contentPrice button:hover::before {
  content: "Select";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #ffffff;
}

.world-travel-plans-wrapper {
  position: relative;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.WorldTravel-Plannote {
  max-width: 894px;
  margin: 0px auto;
  padding: 25px 0 58px 0;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
}

.WorldTravel-primaryCta {
  transform: translateY(20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  gap: 30px;
  max-width: 1006px;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  margin: 0 auto;
}

.WorldTravel-primaryCta p {
  margin-bottom: 0px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: "calt" off;
  color: #333333;
}

.WorldTravel-primaryCta h1 {
  margin-bottom: 0px;
}
.WorldTravel-primaryCta h3 {
  margin: 0;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
}

.WorldTravel-primaryCta button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ffffff00;
  border: 1px solid #ffffff00;
  padding: 0px;
  gap: 20px;
  width: 186px;
  height: 52px;
  border-radius: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.WorldTravel-primaryCta button:hover,
.WorldTravel-primaryCta button:active,
.WorldTravel-primaryCta button:focus {
  padding: 16px 32px;
  gap: 8px;
  position: relative;
  background: #f35c5c;
  border: 1px solid #f35c5c;
  background-color: #f35c5c !important;
  border-color: #f35c5c !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 74px;
  width: 210px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WorldTravel-primaryCta button p {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #f35c5c;
  white-space: nowrap;
  text-transform: capitalize;
}
.WorldTravel-primaryCta h1 {
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #333333;
}
.WorldTravel-primaryCta button div {
  width: 56px;
  height: 52px;
  padding: 16px 32px;
  background: #f35c5c;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 74px;
  display: grid;
  place-content: center;
}

.WorldTravel-primaryCta button:hover p {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #ffffff;
  white-space: nowrap;
}

.WorldTravel-primaryCta button:hover div {
  width: 22px;
  height: 22px;
  padding: 0px;
  background: transparent;
  box-shadow: none;
}

.plansCard-packagelist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 15px;
  gap: 5px;
  margin-top: 10px;
  background: #f8f8f8;
  border-radius: 20px;
}

.WorldTravel-plansCard:last-child:after {
  display: none !important;
  position: absolute;
  content: "";
  width: 67.29px;
  height: 67.29px;
  background-image: url(../../assets/images/worldTravel/inclusiveTag.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 20px;
  right: -33.5px;
  box-shadow: 0px 5.45396px 7.2526px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}
/* ***************************************************** */
.WorldTestimonial-content {
  position: relative;
}

.WorldTestimonial-content h3 {
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #252d43;
  max-width: 627px;
  margin: 0 auto;
  margin-top: 139px;
  margin-bottom: 60px;
  position: relative;
}
.WorldTestimonial-content h3:before {
  content: "";
  position: absolute;
  left: -272px;
  top: -50px;
  background: url(../../assets/images/worldTravel/Polygon1.svg) no-repeat center;
  width: 28px;
  height: 28px;
}
.review-slider {
  position: relative;
}
.review-slider:before {
  content: "";
  position: absolute;
  background: url(../../assets/images/worldTravel/Polygon2.svg) no-repeat center;
  width: 40px;
  height: 40px;
  left: 0;
  bottom: 72px;
}
.review-slider:after {
  content: "";
  position: absolute;
  background: url(../../assets/images/worldTravel/Polygon3.svg) no-repeat center;
  width: 30px;
  height: 30px;
  right: -80px;
  bottom: 300px;
}

.review-slider-content {
  margin: 0 20px;
}

.review-slide {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.review-slider .slick-prev:before {
  content: "";
  background: url(../../assets/images/worldTravel/preslide.svg) no-repeat center;
  width: 12px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-50%);
}
.review-slider .slick-next:before {
  content: "";
  background: url(../../assets/images/worldTravel/nextslide.svg) no-repeat
    center;
  width: 12px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(-50%);
}
.review-slider .slick-prev,
.review-slider .slick-next {
  top: 20%;
}
.review-slider .slick-prev {
  left: -68px;
}
.review-slider .slick-next {
  right: -68px;
}

.review-slider .slick-dots {
  bottom: 125px;
}
.review-slide-head img {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 8.94092px 11.8895px rgba(0, 0, 0, 0.2));
  width: 120px;
  height: 120px;
  border-radius: 100%;
}
.review-slider .slick-list {
  padding-bottom: 252px !important;
}
.review-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #6243ce;
}
.review-slide-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.review-slide-center p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: "calt" off;

  /* text */
  color: #333333;
  margin: 0;
}

.review-slide-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 10px 32.0625px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.review-slide-bottom p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
  opacity: 0.7;
}

.review-slide-bottom .review {
  display: flex;
  gap: 5px;
}

/************ Chat Button ***********/
button.chat-icon {
  filter: drop-shadow(0px 8.94092px 11.8895px rgba(0, 0, 0, 0.2));
  background: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  position: fixed;
  bottom: 20px;
  z-index: 9;
  bottom: calc(1rem + env(safe-area-inset-bottom));

}
.chat-icon img {
  width: 100%;
  height: 100%;
}
.chat-icon.close-icon {
  width: 12px;
  height: 12px;
  right: 50px;
}
.chat-icon.open-chat-icon {
  width: 100px;
  height: 100px;
  right: 27px;
}

/************ Chat Popup ***********/
.chat-popup {
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 12px 33px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: all ease-in 0.3s;
  width: 100%;
  max-width: 381px;
  bottom: 30px;
  right: 65px;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
}
.chat-popup.show-chat {
  visibility: visible;
  opacity: 1;
}
.chat-popup.hide-chat {
  visibility: hidden;
  opacity: 0;
}
.chat-popup-inner {
  position: relative;
  padding: 26px 24px 29px;
  font-family: "Raleway";
  font-style: normal;
}
.chat-popup-inner .close-btn {
  background: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  position: absolute;
  right: 21px;
  top: 21px;
  width: 10px;
  height: 10px;
}
.chat-popup-inner .close-btn img {
  width: 100%;
  height: 100%;
}
.chat-bot-image {
  background: transparent;
  border: 2px solid #f1f1f1;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  overflow: hidden;
  margin: auto;
  padding: 8.5px;
}
.chat-bot-image-inner {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(185, 185, 185, 0.12) 63.1%,
    rgba(112, 112, 112, 0.2) 100%
  );
  padding: 16px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-bot-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-content {
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin: 25px 0 30px;
}
.chat-content span {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}
.chat-content p {
  font-size: 18px;
  line-height: 21px;
  margin: 10px 0 0;
}
.chat-action-section {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 277px;
  margin: auto;
}
.chat-action-section .action {
  display: flex;
  flex-direction: column;
  gap: 11px;
  justify-content: center;
  align-items: center;
}
.chat-action-section .action .action-img {
  box-shadow: 0px 4.47046px 5.94476px rgba(0, 0, 0, 0.2);
  padding: 14.1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in 0.15s;
}
.chat-action-section .action .action-img:hover {
  cursor: pointer;
  transform: scale(1.15);
}
.chat-action-section .action .action-img img {
  width: 21.95px;
  height: 21.95px;
}
.chat-action-section .action .action-img.whatsapp {
  background: #2cd674;
}
.chat-action-section .action .action-img.call {
  background: #f35c5c;
}
.chat-action-section .action .action-img.call img {
  width: 20.9px;
  height: 22.13px;
}
.chat-action-section .action .action-img.calendar {
  background: #f4c431;
}
.chat-action-section .action span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #6e6f6f;
}

@media screen and (max-width: 1024px) {
  .inculsivePoster {
    right: -2px;
  }
}

@media screen and (max-width: 991px) {
  .WorldTravel-transform {
    justify-content: center;
    flex-wrap: wrap;
  }
  .WorldTravel-transform-card1 {
    flex-direction: column-reverse;
  }
  .worldTravelTransform-content h2 {
    text-align: center;
  }

  .worldTravelTransform-content {
    align-items: center;
  }
  .WorldTravel-plansCards {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .top-footer {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  .footer-menu ul {
    flex-wrap: wrap;
  }

  .bottom-footer {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .footer-goto {
    position: fixed;
    bottom: 50px;
    right: 40px;
  }
  .review-slider .slick-next,
  .review-slider .slick-prev {
    display: none !important;
  }
  .review-slider:after {
    right: 0;
  }
  .WorldTestimonial-content h3:before {
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .main-navigation.topChange {
    position: absolute;
    width: 100%;
    top: 0px !important;
  }

  .WorldTravel-Plannote {
    padding-bottom: 40px;
  }
  .content {
    padding-top: 60px;
  }

  .WorldTravel-transform {
    margin-top: 35px;
    flex-direction: column;
    width: 100%;
    position: relative;
    gap: 0px;
  }

  .worldTravelTransform-content h2 {
    font-size: 20px;
    text-align: center;
  }

  .WorldTravel-intro {
    gap: 20px;
    padding-bottom: 17px;
  }

  .WorldTravel-intro h2 {
    font-size: 26px;
  }

  .WorldTravel-descp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 40px;
  }

  .WorldTravel-plans {
    padding-top: 33px;
    padding-bottom: 0;
  }
  .WorldTravel-plans .WorldTravel-transform {
    margin-top: 0px;
  }

  .worldTravelTransform-content {
    align-items: center;
    gap: 320px;
    max-width: 100%;
  }

  .worldTravelTransform-content p {
    text-align: left;
  }
  .worldTravelTransform-image {
    max-width: 100%;
  }

  .worldTravelTransform-image img {
    max-width: 100%;
    width: 100%;
    position: absolute;
    top: 11%;
    left: 0%;
  }

  .WorldTravel-plansTitle h2 {
    font-size: 26px;
    text-align: center;
  }

  .WorldTravel-plansCards {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .WorldTravel-primaryCta {
    padding: 20px;
  }

  .WorldTravel-primaryCta h1 {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .WorldTravel-primaryCta h3 {
    font-size: 20px;
  }

  .WorldTravel-primaryCta p {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .WorldTravel-plans::before {
    height: 60px;
    background-image: url(../../assets/images/worldTravel/curveBgMob.svg);
  }
  .bottom-footer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .review-slider .slick-list {
    padding: 0 0 120px 0 !important;
  }
  .review-slider-content {
    margin: 0 10px;
  }
  /* .WorldTravel-plans .container {
    padding: 0;
  } */
  .review-slider .slick-dots {
    bottom: 90px;
  }
  .WorldTravel-testimonial .container {
    padding: 0 !important;
  }
  .WorldTestimonial-content {
    padding-top: 100px;
  }
  .WorldTestimonial-content h3 {
    margin-top: 23px;
  }
  .WorldTestimonial-content h3:before {
    left: 50px;
    top: -77px;
  }
  .review-slider:before {
    display: none;
  }
  .review-slider:after {
    right: 0;
    bottom: 20px;
  }

  .chat-popup {
    width: 323px;
    max-width: 90%;
    right: 30px;
  }

  .chat-icon.close-icon,
  button.chat-icon {
    right: 33px;
  }
  .WorldTravel-descp-content {
    padding-bottom: 31px;
    gap: 35px;
  }
  .WorldTravels-plans {
    padding-top: 19px;
  }
}

@media screen and (max-width: 576px) {
  .chat-popup-inner {
    padding: 40px 10px 27px 10px;
  }
  .chat-action-section {
    padding-bottom: 15px;
  }
  .chat-popup {
    left: 50%;
    transform: translate(-50%);
  }
  .chat-popup-inner .close-btn {
    right: 13px;
    top: 8px;
  }
}
