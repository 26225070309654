.pricing-page {
    padding-top: 67px;
    padding-bottom: 186px;
    background: #f6f6f6;
}
.heading-section {
    padding-bottom: 50px;
}
.heading-section p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #6e6f6f;
    margin: 0;
}
.heading-section h2 {
    font-weight: 400;
    font-size: 33px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    text-align: center;
    padding-bottom: 10px;
}
.pricing-columns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.pricing-box-sections {
    width: calc(30%  - 20px);
    padding: 28px 0 0;
    max-width: 350px;
    border-radius: 10px;
    border: 2px solid transparent;
    overflow: hidden;
    position: relative;
}
.pricing-box-sections.popular {
    border-color: #f35c5c;
    background: #f35c5c;
}
.pricing-box-inner {
    min-height: 635px;
    width: calc(100% - 2px);
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 7px 11px rgb(0 0 0 / 7%);
    border-radius: 10px;
    margin: auto;
    padding: 40px 20px 30px;
}
.pricing-box-sections.popular .pricing-box-inner {
    border-radius: 0 0 8px 8px;
    box-shadow: none;
}
.pricing-box-sections .popularTxt {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    margin: 0;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 2px;
}
.list-section {
    list-style-type: none;
    padding: 30px 0;
    margin: 0;
}
.list-section li {
    font-weight: 500;   
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #6e6f6f;
    margin: 0;
    padding-bottom: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}
.list-section li i {
    color: #6243ce;
    margin-right: 5px;
    font-size: 13px;
    margin-top: 2px;
}

.pricing-box-icon {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-bottom: 30px;
}
.pricing-box-icon img {
    width: 40px;
    height: 40px;
}
.pricing-box-icon h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #333333;
    margin: 0;
    padding-top: 10px;
}
.pricing-box-rate-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}
.pricing-box-rate-section h4 {
    /* font-family: "Raleway", sans-serif !important; */
    font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.01em;
        margin: 0;
}
.main-price-rate {
color: #6243ce;
font-size: 32px;
position: relative;
}
.discount-rate {
    color: #f35c5c;
    font-size: 25px;
    position: absolute;
    right: 14%;
    top: -16px;
}
.discount-line {
    background: #f35c5c;
    height: 1.5px;
    width: 87%;
    position: absolute;
    transform: rotate(160deg);
    left: 22%;
    top: 52%;
}
.pricing-box-rate-section p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #c5cbcb;
    margin: 0;
}
.pricing-btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-icon-section {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 19% 19% 19% 19% 19%;
    align-items: center;
    width: 100%;
    bottom: 8px;
    left: 0px;
    position: absolute;
    padding: 0 10px;
    gap: 1%;
}
.payment-icon-section img {
    object-fit: contain;
    width: 100%;
    height: 20px;
}

/************** Pricing Modal ***************/
.pricing-modal .modal-dialog {
    max-width: 1000px;
    width: 97%;
}
.pricing-modal .modal-content {
    /* background: linear-gradient( 180deg, rgba(78, 50, 177, 1) 0%, rgba(78, 50, 177, 1) 124.14% ); */
    padding: 8px;
    border-radius: 10px;
    color: #333333;
}
.pricing-modal .modal-header {
    border: 0;
    border-bottom: 3px solid #f35c5c;
    position: relative;
}
.modal-close-button {
    width: 25px;
    height: 25px;
    box-shadow: none !important;
    border: none  !important;
    background: transparent !important;
    padding: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 10px;
}
.modal-close-button:hover, .modal-close-button:focus, .modal-close-button:focus-within {
    opacity: 0.8;
    box-shadow: none !important;
    border: none  !important;
    background: transparent !important;
}
.modal-close-button img {
    width: 100%;
    height: 100%;
}
.pricing-modal .modal-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.01em;
    margin: 0;
    font-family: "DM Serif Display", serif !important;
}

.pricing-form-section {
    display: grid;
    grid-template-columns: calc(25% - 5px) calc(25% - 5px) calc(25% - 5px) calc(25% - 5px);
    gap: 10px;
    justify-content: space-between;
}

.pricing-form .form-control {
    box-shadow: none !important;
    border: none  !important;
    background: #f5f8f8;
    border-radius: 122px;
    width: 100%;
    margin-bottom: 11px;
    border-color: #f5f8f8;
    padding: 10px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #6e6f6f;
    font-family: "DM Serif Display", serif !important;
    height: 50px;
}
.pricing-form .orange-button {
    height: 50px !important;
    margin: auto;
}







/****** Hover On Card ******/
/* .pricing-box-sections:hover .pricing-box-inner,
.pricing-box-sections.popular:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.pricing-box-sections.popular:hover .pricing-box-inner {
transform: none;
} */



/********************************************
Media Queries ***********************************/

@media (max-width: 768px) {
    .pricing-columns {
        gap: 20px;
    }
    .pricing-box-sections {
        width: 45%;
    }
    .pricing-box-inner {
        height: auto;
    }
    .pricing-form-section {
        grid-template-columns: calc(50% - 5px) calc(50% - 5px);
        gap: 10px;
    }
}

@media (max-width: 576px) {
    .pricing-columns {
        gap: 50px;
    }
    .pricing-box-sections {
        width: 100%;
    }
    .pricing-form-section {
        grid-template-columns: 100%;
        gap: 10px;
    }

}