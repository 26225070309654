.login-page {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page:before {
  content: '';
  background: url(../../assets/images/login/bg.png);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
}
.login-page:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background: linear-gradient(
    180deg,
    rgba(78, 50, 177, 0.9) 0%,
    rgba(78, 50, 177, 0) 124.14%
  );
}
.login-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 9;
  max-width: 900px;
  padding: 20px;
  width: 100%;
}
.login-form {
  background: #ffffff;
  box-shadow: 0px 24.5184px 32.0625px rgb(0 0 0 / 10%);
  border-radius: 20px;
  padding: 50px;
  max-width: 450px;
  width: 100%;
}
.login-content {
  max-width: 300px;
  width: 100%;
}
.login-content .logo-login {
  margin: 0px auto;
  display: block;
}
.login-content h2 {
  font-weight: 400;
  font-size: 50px;
  line-height: 104.5%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: 0;
  padding: 64px 0px 68px;
  text-align: center;
}
.login-contact-info span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ffffff;
  display: block;
  padding-bottom: 5px;
}
.login-contact-info span img {
  margin-right: 10px;
}
.login-social ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.login-social {
  padding-top: 50px;
}
.login-social li {
  margin: 0px 12px;
}
.login-social li img {
  width: 30px;
}
.login-contact-info {
  max-width: 220px;
  margin: 0px auto;
}
.login-popup-content h2 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 30px;
}
.login-popup-content h2 {
  font-weight: 400;
  font-size: 33px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 30px;
}
.login-popup-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
  opacity: 0.7;
  margin: 0;
  padding-bottom: 30px;
}
.login-popup-content form .form-control,
.login-popup-content form .form-select {
  background: #f5f8f8;
  border-radius: 122px;
  height: 54px;
  border-color: #f5f8f8;
  margin-bottom: 10px;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  box-shadow: none !important;
  box-sizing: border-box;
  width: 100%;
}
.login-popup-content .form-control::placeholder {
  color: #ced0d0;
}
.forget-section {
  padding: 20px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remember-me label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
}
.forget-section a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
}
.login-form .btn {
  background: #f35c5c;
  box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 12%),
    0px 2px 4px -1px rgb(0 0 0 / 7%) !important;
  border-radius: 33px;
  border-color: #f35c5c;
  width: 100%;
  height: 55px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.login-form .btn:after {
  content: '';
  background: url(../../assets/images/login/login-icon.svg);
  width: 22px;
  height: 19px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 8px;
  position: relative;
  top: 2px;
  display: inline-block;
}
.login-form .btn:hover,
.login-form .btn:focus {
  background: #6243ce !important;
  border-color: #6243ce !important;
}
.login-form .form-check-input {
  cursor: pointer;
}
.login-form .form-check-input:focus {
  box-shadow: none;
}
.one-time {
  padding-top: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one-time a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
  opacity: 0.7;
  margin: 0;
  display: block;
  cursor: pointer;
  transition: all 0.5s;
}
.one-time a:hover {
  color: #6243ce;
  opacity: 1;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.pass-show {
  position: relative;
}
.pass-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
.pass-icon img {
  width: 24px;
}
.resend-otp {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.resend-otp span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #333333;
  opacity: 0.7;
  cursor: pointer;
}
.login-popup-content p {
  opacity: 1;
  padding-bottom: 10px;
}

.form-or-text {
  text-align: center;
  color: rgb(51 51 51 / 50%) !important;
}
@media screen and (max-width: 991px) {
  .login-form {
    padding: 40px;
    max-width: 400px;
  }
}
@media screen and (max-width: 767px) {
  .login-page {
    padding-top: 80px;
    align-items: flex-start;
    height: auto;
  }
  .login-content h2 {
    padding: 30px 0px 30px;
    font-size: 35px;
  }
  .login-content {
    max-width: 300px;
    width: 100%;
    margin: 0px auto;
    padding-bottom: 35px;
  }
  .login-form {
    margin: 0px auto;
  }
  .login-content .logo-login {
    display: none;
  }
  .hide-layout .header {
    display: block;
  }
  .login-social {
    padding-top: 25px;
  }
  .login-form {
    padding: 30px;
  }
  .login-popup-content p {
    max-width: 240px;
    padding-bottom: 15px;
  }
  .login-popup-content h2 {
    font-size: 26px;
    padding-bottom: 15px;
  }
  .forget-section {
    padding: 1px 0px 11px;
  }
}
