.header {
  position: relative;
  z-index: 999;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #6243ce !important;
}

.white-header.is-sticky .main-navigation {
  position: unset;
  background: rgb(51 51 51 / 70%);
  top: unset;
  border-bottom: 1px solid rgb(51 51 51 / 70%);
}
.headroom-wrapper {
  height: auto !important;
}
.header.extra-padding .headroom.headroom--pinned .main-navigation {
  top: 80px;
}
.header .headroom.headroom--pinned .main-navigation {
  background: rgb(51 51 51 / 70%);
  border-bottom: 1px solid rgb(51 51 51 / 70%);
  top: 0px;
}

.is-sticky .main-navigation {
  position: static;
  background: #fff;
  top: 0px;
}

._access-icon.material-icons {
  top: 36%;
  right: 15% !important;
  background: #ffffff !important;
  box-shadow: none;
  opacity: 0.9 !important;
  border-radius: 100%;
  width: 33px !important;
  height: 33px !important;
  line-height: 33px !important;
  font-size: 22px !important;
  color: #ffffff !important;
}

._access-icon.material-icons:before {
  content: "";
  background: url(../../assets/images/header/accessibility.svg);
  width: 17px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._access-icon.material-icons:hover {
  box-shadow: none;
  transform: none;
}

._access-menu._access.bottom.right.type {
  font-family: "Raleway", sans-serif !important;
}

._access-menu ul._access-scrollbar li {
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 500;
}

._access-menu._access.bottom h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

._access-menu._access.bottom ._menu-btn {
  top: 15px;
}
.top-header-banner.show {
  top: 0;
  position: fixed;
}

.top-header {
  position: absolute;
  padding: 8px 0px;
  background: #f5f8f8;
  color: rgb(51 51 51 / 50%);
  top: 80px;
  /* top: 0px; */
  width: 100%;
}

.white-header .top-header {
  padding: 8px 0px;
  background: #333333;
  color: #ced0d0;
}

.top-header-banner {
  /* display: none; */
  position: relative;
  width: 100%;
  background: #f5f8f8;
  z-index: 99;
}

.headerBanner-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.headerBanner-title {
  display: flex;
  flex-direction: column;
  gap: 11px;
  align-items: flex-start;
}

.headerBanner-title .kutumText {
  font-family: "Kaushan Script", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 22px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
}

.headerBanner-title .Ralewaytext {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #6243ce;
}

.headerBanner-learnBtn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  width: 200px;
  height: 45px;
  background: #6243ce;
  background-color: #6243ce;
  border-color: #6243ce;
  border-radius: 74px;
  border: 1px solid #6243ce;
}

.headerBanner-learnBtn button:hover,
.headerBanner-learnBtn button:active {
  background: #6243ce;
  background-color: #6243ce;
  border-color: #6243ce;
  border: 1px solid #6243ce;
  gap: 20px;
  transition: gap 0.2s ease-in-out;
}

.headerBanner-learnBtn button:focus {
  background: #ced0d0 !important;
  border-color: #ced0d0 !important;
  gap: 20px;
  transition: gap 0.2s ease-in-out;
}

.header.extra-padding {
  padding-bottom: 80px;
}
.top-header-banner.close {
  display: none;
  transition: all 0.2s ease-in-out;
}

.is-sticky .main-navigation.topChange {
  top: unset !important;
  position: unset;
}
.is-sticky .main-navigation.navigation-with-banner {
  margin-top: 80px;
}

.main-navigation.topChange {
  position: absolute;
  width: 100%;
  top: 42px;
}

.top-header.headerTopChange {
  top: 0px;
}

.headerBanner-learnBtn button p {
  margin-bottom: 0px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #ffffff;
}

.headerBanner-cancelBtn {
  position: absolute;
  right: 5%;
  cursor: pointer;
  top: 32%;
}
.headerBanner-cancelBtn img {
  width: 18px;
  height: 18px;
}

.top-header-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-info {
  display: flex;
  align-items: center;
}

.contact-info span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin-right: 45px;
}

.white-header .contact-info span img {
  opacity: 1;
}

.contact-info span img {
  margin-right: 10px;
  opacity: 0.5;
}

.search-icon {
  margin-right: 40px;
  cursor: pointer;
  display: none;
}

.mobile-icons span:first-child {
  display: none;
}

.auth-login {
  display: flex;
  align-items: center;
}

.white-header .auth-login a {
  color: #ced0d0;
}

.auth-login a {
  color: rgb(51 51 51 / 50%);
  margin-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  text-decoration: none;
}

.white-header .auth-login a:hover {
  color: #ced0d0 !important;
}

.auth-login a:hover {
  color: rgb(51 51 51 / 50%) !important;
}

.auth-login a span {
  display: inline-block;
  margin-right: 20px;
}

.language-dropdown {
  margin-right: 20px;
}

.language-dropdown .btn,
.language-dropdown .btn:hover,
.language-dropdown .btn:focus,
.language-dropdown .show > .btn-success.dropdown-toggle:focus,
.language-dropdown .btn-success:active:focus,
.language-dropdown .show > .btn-success.dropdown-toggle {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  padding: 0px;
  position: relative;
}

.language-dropdown .dropdown-toggle::after {
  border: none;
  content: "";
  background: url(../../assets/images/header/arrow.svg);
  position: absolute;
  width: 9px;
  height: 5px;
  background-size: cover;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0px;
}

.language-mobile {
  display: none;
}

.main-navigation {
  position: absolute;
  width: 100%;
  top: 120px;
  /* top: 42px; */
  padding: 21px 0px 19px;
  border-bottom: 2px solid #f5f8f8;
  /* background-color: #fff; */
}
.headroom.headroom--pinned .menu-icon,
.headroom.headroom--pinned .mobile-icons {
  filter: brightness(0) invert(1);
}

.white-header .main-navigation {
  border-bottom: 1px solid rgb(245 248 248 / 20%);
}

.main-navigation-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.menu-icon span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin-right: 26px;
}

.white-header .menu-icon span {
  color: #ffffff;
}

.menu-grid {
  position: absolute;
  right: 63px;
  transition: all 0.2s;
  opacity: 0;
}

.menu-grid a,
.menu-grid .nav-item span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  display: inline-block;
  padding: 0px 35px;
  cursor: pointer;
}
.headroom.headroom--pinned .menu-grid .nav-item span,
.headroom.headroom--pinned .menu-grid a {
  color: #fff;
}

.white-header .menu-grid a,
.white-header .menu-grid .nav-item span {
  color: #ffffff;
}

.menu-grid a:hover,
.menu-grid a:focus,
.menu-grid .nav-item span:hover,
.menu-grid .nav-item span:focus {
  color: rgb(145 145 145 / 90%) !important;
}

.white-header .menu-grid a:hover,
.white-header .menu-grid a:focus,
.white-header .menu-grid .nav-item span:hover,
.white-header .menu-grid .nav-item span:focus {
  color: rgba(255, 255, 255, 0.6) !important;
}

.open-menu .menu-grid {
  opacity: 1;
}

.open-menu .menu-icon span,
.open-menu .menu-icon img {
  display: none;
}

.white-header .main-navigation.open-menu {
  background: rgba(51, 51, 51, 0.7);
  border-bottom: 1px solid rgba(51, 51, 51, 0.7);
}

.open-menu .logo {
  opacity: 0;
}

.open-menu .menu-icon:before {
  content: "";
  background: url(../../assets/images/header/close-dark.svg);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 5px;
}

.white-header .open-menu .menu-icon:before {
  content: "";
  background: url(../../assets/images/header/close.svg);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 5px;
}

.mobile-icons {
  display: none;
}

.searc-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-content {
  max-width: 437px;
  width: 100%;
}

.search-content img {
  margin: 0px auto;
  display: block;
  margin-bottom: 38px;
}

.search-content form input {
  background: #f5f8f8;
  border-radius: 122px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ced0d0;
  height: 54px;
  padding: 10px 24px;
}

.search-content form input:focus {
  background: #f5f8f8;
  box-shadow: none;
  border-color: #f5f8f8;
}

.search-content form input::placeholder {
  color: #ced0d0;
}

.search-content form {
  position: relative;
}

.search-content form .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  background: #f35c5c;
  border-radius: 100%;
  border-color: #f35c5c;
  line-height: 45px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.search-content form .btn:focus {
  box-shadow: none;
}

.search-content form .btn img {
  margin: 0px auto;
}

.search-close-btn {
  background: #6243ce;
  width: 45px;
  height: 45px;
  opacity: 1;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute;
  right: 20%;
  top: 20%;
  cursor: pointer;
}

.search-close-btn img {
  margin: 0px auto;
}

.language-dropdown .dropdown span {
  color: rgb(51 51 51 / 50%);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
}

.language-dropdown .dropdown span.white-lang {
  color: #ced0d0;
}

.language-dropdown .dropdown-item:active {
  color: #1e2125;
  background-color: #e9ecef;
}

.payment-success .thank-you-page {
  height: auto;
}

.payment-success .content {
  padding: 0;
}

.payment-success .thank-you-page:after {
  display: none;
}

.payment-success .thank-you-grid {
  padding: 30px;
  min-height: auto;
}

.payment-success .thank-you-content h2 {
  padding-top: 0px;
}

.payment-popup .login-form {
  box-shadow: none;
  margin: 0px auto;
}

.white-header .language-dropdown a,
.white-header .language-dropdown a:hover,
.white-header .language-dropdown a:focus {
  margin: 0 !important;
  text-align: left !important;
  color: black !important;
  font-weight: 600;
}

.top-header-grid .language-dropdown.my-app a,
.top-header-grid .language-dropdown.my-app a:hover,
.top-header-grid .language-dropdown.my-app a:focus {
  color: white !important;
  font-size: 12px;
}

.language-dropdown.my-app {
  margin-right: 0px;
  margin-left: 50px;
}

.top-header-grid .language-dropdown.my-app a img {
  margin-left: 12px;
}

@media screen and (max-width: 1840px) {
  ._access-icon.material-icons {
    right: 8% !important;
    top: 46%;
  }
}

@media screen and (max-width: 1540px) {
  ._access-icon.material-icons {
    right: 2px !important;
    top: 50%;
  }
}

@media screen and (max-width: 1280px) {
  .headerBanner-cancelBtn {
    position: absolute;
    right: -3%;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .headerBanner-container {
    position: relative;
    display: flex;
    width: 98%;
  }
}

@media screen and (max-width: 991px) {
  .headerBanner-img img {
    width: 100%;
  }

  .headerBanner-title {
    gap: 8px;
  }

  .headerBanner-title .kutumText {
    font-size: 20px;
  }

  .headerBanner-title .Ralewaytext {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  html {
    overflow-x: hidden;
  }
  .headroom.headroom--pinned .open-menu .menu-icon {
    filter: none;
  }
  ._access-icon.material-icons {
    position: fixed;
    top: 50%;
    right: 10px !important;
    left: auto;
    z-index: 9;
  }

  ._access-menu._access.bottom h3 {
    font-size: 16px !important;
  }

  ._access-menu._access.bottom ._menu-btn {
    top: 8px;
    font-size: 20px !important;
  }

  ._access-menu ul._access-scrollbar li {
    font-size: 12px !important;
  }

  ._access-menu ul._access-scrollbar li:before {
    font-size: 20px !important;
  }

  .top-header {
    display: none;
  }

  .main-navigation {
    top: 183px;
    padding: 19px 0px 19px;
  }
  .headerBanner-cancelBtn {
    top: 5px;
    right: 10px;
  }
  .header.extra-padding {
    padding-bottom: 177px;
  }
  /* .header.menu-open {
        z-index: ;
    } */
  .header.menu-open .top-header-banner.show {
    z-index: unset;
  }

  .is-sticky .main-navigation {
    top: 183px !important;
  }

  .logo img {
    width: 100%;
  }

  .logo {
    width: 100px;
  }

  .menu-icon span {
    display: none;
  }

  .mobile-icons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 50px;
  }

  .header.white-header .mobile-icons a {
    color: #fff !important;
  }
  .header .mobile-icons a {
    color: #000000;
  }
  .mobile-icons span {
    display: inline-block;
    margin: 0px 15px;
    cursor: pointer;
  }

  .search-content {
    padding: 0px 15px;
  }

  .search-close-btn {
    right: 12px;
    top: 36px;
  }

  .search-content img {
    margin-bottom: 30px;
    width: 120px;
  }

  .search-content form .btn img {
    width: auto;
  }

  .search-close-btn img {
    width: auto;
    margin: 0px auto;
  }

  .menu-icon {
    z-index: unset;
  }

  .open-menu .menu-icon {
    z-index: 99999;
    background: #6243ce;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    position: fixed;
    right: 12px;
    top: 36px;
  }

  .menu-grid {
    display: none;
  }

  .open-menu .menu-grid {
    position: fixed;
    top: 0;
    background: rgb(0 0 0 / 90%);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: block;
    overflow-y: auto;
    height: 100vh;
  }
  .is-sticky .main-navigation.navigation-with-banner {
    margin-top: 183px !important;
  }

  .main-navigation.open-menu {
    background: transparent;
    border-bottom: 1px solid transparent;
  }

  .main-navigation .nav {
    margin-top: 115px;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .menu-grid a,
  .menu-grid .nav-item span {
    font-weight: 700;
    padding: 21px 20px;
    color: #fff;
  }

  .open-menu .menu-icon:before {
    content: "";
    background: url(../../assets/images/header/close.svg);
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    right: 5px;
  }

  .open-menu .menu-icon:before {
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .language-mobile {
    display: block;
    padding-top: 40px;
  }

  .language-mobile .contact-info span {
    display: block;
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #ffffff;
    padding-bottom: 10px;
  }

  .language-mobile .language-dropdown .dropdown {
    text-align: center;
  }

  .language-mobile .language-dropdown {
    margin: 0;
    padding-top: 40px;
    position: relative;
  }

  .language-mobile .show > .btn-success.dropdown-toggle {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .language-mobile .dropdown-menu {
    background: transparent;
  }

  .language-mobile .dropdown-menu {
    position: relative !important;
    transform: translate(0, 0) !important;
  }

  .language-mobile .dropdown-menu .dropdown-item {
    text-align: center !important;
    display: block;
    margin: 0 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #ffffff !important;
    padding: 10px 0px;
  }

  .contact-info span img {
    opacity: 1;
  }

  /* banner */
  .top-header-banner {
    background: linear-gradient(
        257.86deg,
        rgba(151, 71, 255, 0.385) 30.36%,
        rgba(151, 71, 255, 0) 80.69%
      ),
      #e7e1f8;
    /* btn shadows */
    height: 183px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  }

  .headerBanner-title {
    gap: 10px !important;
    padding-top: 24px !important;
  }

  .headerBanner-img img {
    position: absolute;
    width: 135px !important;
    left: -4%;
    bottom: -16%;
  }

  .headerBanner-title .kutumText {
    font-weight: 400;
    font-size: 32px !important;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #f5f8f8;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  }

  .headerBanner-title .Ralewaytext {
    font-weight: 900;
    font-size: 19px !important;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #6243ce;
  }

  .headerBanner-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }

  .headerBanner-learnBtn {
    margin-top: 9px;
  }
}
