@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Dancing+Script&family=Raleway:wght@400;500;600;700&display=swap");

body {
    font-family: "Raleway", sans-serif !important;
    margin: 0;
    padding: 0;
    font-weight: 500;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "DM Serif Display", serif !important;
}

.cursive-font {
    font-family: "Dancing Script", cursive !important;
}

.container {
    max-width: 1230px !important;
}

.hide-layout .header,
.hide-layout .footer {
    display: none;
}

.content {
    /* padding-top: 75px; */
    padding-top: 120px;
}

.white-layout .content {
    padding-top: 0px;
}

.loader {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 9999;
}

.loader .spinner-grow {
    width: 40px;
    height: 40px;
    color: #f35c5c !important;
    vertical-align: -0.125em;
}

.payment-user-detail {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.pagination {
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    gap: 8px;
}

.pagination a.page-link {
    background: #ffffff;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #212b36;
}

.pagination a.page-link:focus {
    box-shadow: none;
}
.paymentWidgetsFormWrapper {
    padding-top: 40px;
}

.paymentWidgetsFormWrapper .charge-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentWidgetsPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 767px) {
    .container {
        padding: 0px 15px;
    }
}