.paymentHeader {
  background: #6243ce;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9;
}

.addStreetaddress {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paymentHeader img {
  max-width: 80.32px;
  width: 100%;
}

.paymentPage-content {
  background: linear-gradient(to left, #fff 50%, #f5f8f8 50%);
  position: relative;
}

.paymentHeader .container {
  max-width: 1098px !important;
}

.paymentPage-content .container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 1098px !important;
}

.paymentPage-leftSection {
  background: #f5f8f8;
  max-width: 570px;
  width: 100%;
  padding: 50px 70px 70px 0px;
}

.paymentPage-title {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.paymentPage-title h4 {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}

.paymentPage-title p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
  max-width: 539px;
  width: 100%;
}

.paymentPage-title p span,
.paymentPage-title p a {
  color: #f35c5c;
  font-weight: 600;
}
.paymentPage-title p a:hover {
  color: #f35c5c !important;
}

.paymentPage-loginForm,
.paymentPage-BillingAddress {
  padding: 30px 20px;
  margin-top: 30px;
  max-width: 570px;
  background: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.billingAddress-title {
  margin-top: 30px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}

.paymentPage-loginForm form,
.paymentPage-BillingAddress form {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paymentPage-BillingAddress form .form-label {
  margin-bottom: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6243ce;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.paymentPage-BillingAddress form .form-label:hover {
  text-decoration-color: #6243ce;
  text-decoration: underline;
}

.paymentPage-leftSection .form-control {
  padding: 15px 24px;
  gap: 10px;
  height: 54px;
  background: #f5f8f8;
  border: 1px solid #f5f8f8;
  border-radius: 122px;
  width: 100%;
}

.paymentPage-leftSection .react-tel-input .selected-flag {
  padding: 0 0 0 24px;
}

.paymentPage-leftSection .react-tel-input input {
  padding-left: 70px !important;
}

.paymentPage-leftSection .react-tel-input input:focus,
.paymentPage-leftSection .form-control:focus {
  box-shadow: 0px 2px 6px #d1e0e0;
  background: #f5f8f8;
  border: 1px solid #f5f8f8;
}

.paymentPage-rightSection {
  background: #fff;
  padding: 50px 0 50px 60px;
  position: relative;
  min-height: 100%;
  max-width: calc(100% - 570px);
  width: 100%;
  display: flex;
}

.paymentOrderSummary {
  width: 398px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bestChoiceOrder {
  position: absolute;
  top: 6%;
  right: -6%;
  background: #6243ce;
  transform: rotate(11.53deg);
  border-radius: 100%;
  width: 77.41px;
  height: 77.41px;
  color: #fff;
  display: grid;
  place-content: center;
}

.bestChoiceOrder-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bestChoiceOrder h4 {
  font-weight: 700;
  font-size: 15.6736px;
  line-height: 10px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: "calt" off;
  color: #ffffff;
}

.paymentPage p,
.paymentPage h1,
.paymentPage h2,
.paymentPage h4,
.paymentPage h5,
.paymentPage h6 {
  margin-bottom: 0px;
}

.paymentOrderSummary h2 {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}

.paymentOrderSummary img.bestChoiceOrder-Img {
  border-radius: 10px;
  margin-top: 17px;
  width: 100%;
}

.paymentOrderSummary h1 {
  margin-top: 20px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}

.paymentOrderSummary p {
  margin-top: 20px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #333333;
}

.paymentpage-totalBill {
  margin-top: 20px;
  padding: 17px 0px;
  width: 100%;
  border-top: 1px solid #ced0d094;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentpage-totalBill h6 {
  font-family: "Raleway" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
}

.paymentpage-totalBill h4 {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: right;
  letter-spacing: 0.01em;
  color: #333333;
}

.paymentPage-leftSection button {
  margin-top: 30px;
  max-width: 570px;
}

.paymentPage-leftSection button,
.paymentPage-leftSection button:hover,
.paymentPage-leftSection button:active,
.paymentPage-leftSection button:focus,
.paymentOrderSummary button,
.paymentOrderSummary button:hover,
.paymentOrderSummary button:active,
.paymentOrderSummary button:focus {
  padding: 13px 10px;
  width: 100%;
  height: 47px;
  background: #f35c5c !important;
  background-color: #f35c5c !important;
  border-color: #f35c5c !important;
  border: 1px solid #f35c5c !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 33px;
}

.paymentPage-leftSection .form-check,
.paymentOrderSummary .form-check {
  margin-top: 10px;
}

.paymentCheckInput .form-label {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #6e6f6f;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}
.paymentCheckInput {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
.form-check-input {
  border: 2px solid #6243ce;
  cursor: pointer;
}

.form-check-input:checked {
  background: url(../../assets/images/payment/tick.svg) no-repeat center !important;
  background-color: #ffffff;
  accent-color: #6243ce;
  color: #6243ce;
  fill: #6243ce;
  stroke: #6243ce;
  border: 2px solid #6243ce;
}

.form-check-input:focus {
  border-color: #6243ce;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.payment-footer {
  background: #333333;
  padding: 17px;
}
.payment-footer h6 {
  font-family: "Raleway" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #6e6f6f;
}

.form-fixed-part {
  height: fit-content;
  position: sticky;
  top: 120px !important;
}
/* ************************************************** */
.payment-slider button {
  display: none !important;
}
.payment-slider {
  padding: 45px 0;
}
.payment-slider button {
  display: none !important;
}

.payment-slider {
  padding: 45px 0;
}

.payment-review-slide-head img {
  width: 70px;
  height: 70px;
  box-shadow: 0px 3.25246px 5.85443px -0.650492px rgba(0, 0, 0, 0.21);
  border-radius: 100%;
}

.payment-review-slide {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-review-slide-center {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.payment-review-slide-center p {
  font-family: "DM Serif Display";
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  letter-spacing: 0.01em;

  /* primary */
  color: #6243ce;
}
.payment-review-slide-center span {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;

  /* grey 2 */
  color: #6e6f6f;
}

.payment-review {
  display: flex;
  gap: 7px;
}

.payment-review-slide-bottom p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;

  /* primary */
  color: #6243ce;
  opacity: 0.7;
}

.payment-review-slide-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.payment-slider .slick-dots li {
  margin-left: 0;
  margin-right: 40px;
}
.payment-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #6243ce;
}

.payment-slider .slick-dots li button {
  display: block !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  height: 20px;
}

.payment-slider .slick-dots {
  text-align: start;
  bottom: -40px;
}
.payment-logo {
  padding: 50px 0;
  display: flex;
  gap: 27px;
}

@media screen and (max-width: 1024px) {
  .paymentPage-leftSection {
    padding: 40px 50px 50px 0px;
  }

  .paymentPage-rightSection {
    padding: 40px 50px;
  }
}

@media screen and (max-width: 991px) {
  .paymentOrderSummary {
    width: 290px;
  }
  .paymentPage-content {
    background: #f5f8f8;
  }
  .paymentPage-leftSection {
    max-width: 570px;
    margin: 0px auto;
    padding: 40px 0px 40px 0px;
  }
  .paymentPage-rightSection {
    padding: 30px 15px;
    max-width: 570px;
    width: 100%;
    margin: 40px auto;
    margin-top: 0px;
    border-radius: 10px;
    position: unset;
    right: unset;
    justify-content: center;
    align-items: center;
  }
  .form-fixed-part {
    position: unset;
  }
}

@media screen and (max-width: 767px) {
  .paymentPage-content .container {
    display: flex;
    flex-direction: column;
  }

  .paymentPage-content {
    background: #f5f8f8;
  }

  .paymentPage-leftSection {
    padding: 30px 6px;
  }

  .paymentPage-loginForm form,
  .paymentPage-BillingAddress form {
    padding: 20px 10px;
  }

  .payment-footer {
    padding: 17px 0px;
  }

  .paymentOrderSummary {
    width: 100%;
  }

  .bestChoiceOrder {
    scale: 0.8;
  }
}
