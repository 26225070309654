select {
  padding: 5px;
  margin: 5px 5px 5px 0px;
  min-width: 20%;
  border: 1px solid #00000073;
  border-radius: 5px;
}
.contact-page {
  padding-top: 28px;
}
.contact-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-content {
  width: calc(100% - 500px);
}
.visa-evaluation-calc .contact-form {
  max-width: 80%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 24.5184px 32.0625px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 50px 50px 23px;
  position: relative;
  margin-bottom: -40px;
}
.visa-evaluation-calc .contact-content h2 {
  padding-top: 20px;
  margin: 0;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}
.visa-evaluation-calc .contact-form-content label {
  display: block;
  margin-top: 10px;
}
.visa-evaluation-calc .contact-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
}
.visa-evaluation-calc .contact-info-grid {
  width: 48%;
  display: flex;
  position: relative;
  padding-left: 30px;
}
.visa-evaluation-calc .contact-info-grid img {
  position: absolute;
  top: 5px;
  left: 0;
}
.visa-evaluation-calc #submitBtn {
  margin-top: 15px;
}
.visa-evaluation-calc .contact-info-text span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.visa-evaluation-calc .location-place img {
  width: 100%;
}
.visa-evaluation-calc .contact-info-text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #333333;
  margin: 0;
  padding-top: 8px;
}
.visa-evaluation-calc .location-place img {
  width: 100%;
}
.visa-evaluation-calc .contact-info-text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #333333;
  margin: 0;
  padding-top: 8px;
}
.visa-evaluation-calc .contact-form-content h2 {
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 20px;
}
.visa-evaluation-calc .contact-form-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
  margin: 0;
  padding-bottom: 20px;
}
.visa-evaluation-calc .contact-form-content form .form-control {
  background: #f5f8f8;
  border-radius: 122px;
  width: 100%;
  height: 54px;
  margin-bottom: 11px;
  border-color: #f5f8f8;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
}
.visa-evaluation-calc .contact-form-content form .form-control::placeholder {
  color: #ced0d0;
}
.visa-evaluation-calc .contact-form-content form .btn {
  background: #f35c5c;
  border-radius: 33px;
  border-color: #f35c5c;
  width: 100%;
  height: 54px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-top: 10px;
  box-shadow: none !important;
  transition: all 0.5s;
  position: relative;
}
.visa-evaluation-calc .contact-form-content form textarea.form-control {
  height: 155px;
  border-radius: 10px;
}
.visa-evaluation-calc .contact-form-content form .btn:after {
  content: '';
  background: url(../../assets/images/header/btn-arrow-white.svg);
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 8px;
  position: relative;
  top: 2px;
  display: none;
}
.visa-evaluation-calc .contact-form-content form .btn:hover {
  background: #6243ce !important;
  border-color: #6243ce !important;
}
.visa-evaluation-calc .contact-form-content form .btn:hover:after {
  display: inline-block;
}
.visa-evaluation-calc .contact-form-content form .btn:focus {
  background: #ced0d0 !important;
  border-color: #ced0d0 !important;
}
.visa-evaluation-calc .contact-form-content form .btn:focus:hover:after {
  display: none;
}
.success-msg {
  color: #5dd979;
  padding-top: 20px;
  text-align: center;
  font-weight: 500;
}
.error-border {
  border-color: #f35c5c !important;
}
.error-msg {
  color: #f35c5c;
  padding-bottom: 10px;
  padding-left: 26px;
  text-align: left;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.PhoneInput {
  background: #f5f8f8;
  border-radius: 122px;
  width: 100%;
  height: 54px;
  margin-bottom: 11px;
  border-color: #f5f8f8;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6e6f6f;
}
.PhoneInputInput {
  background: transparent;
  border: none;
  color: #6e6f6f;
}
.PhoneInputInput:focus {
  background: transparent;
  border: none;
  outline: none;
}
.PhoneInputInput::placeholder,
.react-tel-input input::placeholder {
  color: #ced0d0 !important;
}
.react-tel-input input {
  padding-left: 60px !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border-color: transparent !important;
}
.visa-evaluation-calc {
  padding: 25px;
  background: white;
  box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
  border-radius: 5px;
}
.visa-evaluation-calc h2 {
  font-weight: 600;
  font-size: 22.115px;
  line-height: 160%;
  text-transform: capitalize;
  color: #303232;
  margin-bottom: 10px;
}
.visa-evaluation-calc .form-group {
  width: 49%;
}
.visa-evaluation-calc form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.visa-evaluation-calc .form-group input,
.visa-evaluation-calc .form-group select {
  width: 100%;
  padding: 9px 10px;
  height: 40px;
  border: 1px solid #ced0d0;
  width: 100%;
  border-radius: 6px;
  margin: 0;
}
.visa-evaluation-calc .adaptability-multi-select input {
  height: initial;
}
.visa-evaluation-calc .form-group {
  margin-bottom: 20px;
}
.visa-evaluation-calc .language-select-group {
  margin-bottom: 20px;
}
.visa-evaluation-calc label {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #6e6f6f;
  margin: 0 !important;
  margin-bottom: 5px;
}
.visa-evaluation-calc .save-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.visa-evaluation-calc .save-btn button {
  width: 100% !important;
  padding: 5px 20px !important;
  max-width: 200px !important;
}
.visa-evaluation-calc label span {
  background: #cecece;
  height: 25px;
  width: 25px;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: black;
  margin-left: 12px;
  margin-top: -5px;
  font-weight: 600;
  text-align: center;
}
.paymentPage-BillingAddress .form-select {
  padding: 15px 24px;
  gap: 10px;
  height: 54px;
  background: #f5f8f8;
  border: 1px solid #f5f8f8;
  border-radius: 122px;
  width: 100%;
}
.paymentPage-BillingAddress .form-select:focus {
  border-color: #f5f8f8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #f5f8f8;
}
@media screen and (max-width: 767px) {
  .contact-page {
    padding-top: 20px;
  }
  .contact-content h2 {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .contact-content {
    width: 100%;
  }
  .contact-content {
    padding-bottom: 45px;
  }
  .contact-form {
    z-index: 9;
    margin: 0px auto;
    margin-bottom: -40px;
    padding: 20px;
  }
  .contact-info-grid {
    width: 100%;
  }
  .contact-info-grid {
    padding-bottom: 20px;
  }
  .contact-form-content h2 {
    font-size: 20px;
  }
  .contact-form-content form .form-control {
    font-size: 14px;
  }
}
